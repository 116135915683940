* {
    box-sizing: border-box;
  }

  body {
    margin: 0;
  }


  .navStyle {
    color: #F5F5F5;
    position: relative;
    z-index: 99;
  }


  .big2Logo {
    position: absolute;
    left: 0;
    font-size: 2.5em;
    font-family: oldskool_game;
    gap: 0.1rem;
    display: flex;
    padding: 4px 0px 0px 10px;
  }

  .loginDropdown {
    position: absolute;
    right: 0;
    display: inline-block;
    color: inherit;
    text-decoration: none ;
    height: 100%;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 25px;
  }

  .loginDropdownContent {
    display: none;
    position: absolute;
    color: black;
    right: 0px;
    top: 1.7rem;
    background-color: rgba(0, 0, 0);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
    border-radius: 5px;
    overflow: hidden;
  }

  .loginDropdown li {
    padding: 0 4rem 0 1rem;
    width: 100%;
    border-top: 1px solid rgb(255, 255, 255);
    background: #101010;
  }

  .loginDropdown li:first-child {
    border-top: 0;
  }

  .loginDropdown:hover .loginDropdownContent {
    display: block;
  }

  .noAccount {
    padding-top: 15px;
    color: white;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
  }

  .registerLink {
    color: blue;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
  }
  .navStyle ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 0rem;
  }

  .navStyle a {
    color: inherit;
    text-decoration: none ;
    height: 100%;
    display:flex;
    align-items: center;
    padding:.5rem;
  }

.loginDropdown li:hover{
  background-color: rgba(255,255,255,0.1);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
}